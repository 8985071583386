import React, { FC } from "react";

import { ILinkField } from "common/types";

import { P } from "@global/Texts";
import { CheckmarkIcon, UnlinkIcon, LinkIcon } from "@global/iconComponents";

const LinkField: FC<ILinkField> = ({ label, value, placeholder, onClick }) => {
	return (
		<div className='flex space-x-4'>
			<P className="text-grey">{label}</P>
			<div className='text-white'>
				{value ? CheckmarkIcon({ width: '24px', heigh: '24px', fill: 'green' }) : placeholder}
			</div>
			<a onClick={onClick} className='fill-yellow-dark cursor-pointer hover:fill-yellow-lighter'>
				{value ? UnlinkIcon({ width: '30px', heigh: '30px', fill: 'current' }) : LinkIcon({ width: '30px', heigh: '30px', fill: 'current' })}
			</a>
		</div>
	)
}

export default LinkField;