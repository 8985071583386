import React from "react";

const CopyIcon = (props) => (
<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M7.46667 0.5H1.86667C1.35333 0.5 0.933334 0.95 0.933334 1.5V8.5H1.86667V1.5H7.46667V0.5ZM7 2.5L9.8 5.5V10.5C9.8 11.05 9.38 11.5 8.86667 11.5H3.72867C3.21533 11.5 2.8 11.05 2.8 10.5L2.80467 3.5C2.80467 2.95 3.22 2.5 3.73333 2.5H7ZM6.53333 6H9.1L6.53333 3.25V6Z" fill="#FFCC00"/>
</svg>

);

export default CopyIcon;
