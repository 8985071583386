import React from "react";

const CloseIcon = (props) => (
<svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M29.3203 63.4783L31.3996 36.3225C46.9445 35.5689 58.5587 34.9344 66.2422 34.4189C69.9452 39.9314 73.668 45.0077 77.4107 49.6476C81.1535 45.2655 84.7278 40.2785 88.1337 34.6866C95.6389 35.1625 104.184 35.5194 113.768 35.7575C117.927 76.8034 120.372 102.551 121.105 113.001C113.659 114.488 104.916 115.49 94.8766 116.005C93.926 105.417 92.9754 93.4996 92.025 80.2538L76.0443 95.185C70.5194 89.613 65.9747 84.7548 62.4103 80.6107C61.44 94.2926 60.7271 106.022 60.2716 115.797L32.7659 114.697L36.776 64.103L29.3203 63.4783Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M75 10.3581C39.2992 10.3581 10.3581 39.2992 10.3581 75C10.3581 110.701 39.2992 139.642 75 139.642C110.701 139.642 139.642 110.701 139.642 75C139.642 39.2992 110.701 10.3581 75 10.3581ZM0 75C0 33.5786 33.5786 0 75 0C116.421 0 150 33.5786 150 75C150 116.421 116.421 150 75 150C33.5786 150 0 116.421 0 75Z" fill="white"/>
</svg>
);

export default CloseIcon;
