import React from "react";

const CheckmarkIcon = (props) => (
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_3454_6246)">
			<path stroke='none' d="M7.99992 1.33331C4.31992 1.33331 1.33325 4.31998 1.33325 7.99998C1.33325 11.68 4.31992 14.6666 7.99992 14.6666C11.6799 14.6666 14.6666 11.68 14.6666 7.99998C14.6666 4.31998 11.6799 1.33331 7.99992 1.33331ZM6.19325 10.86L3.79992 8.46665C3.53992 8.20665 3.53992 7.78665 3.79992 7.52665C4.05992 7.26665 4.47992 7.26665 4.73992 7.52665L6.66659 9.44665L11.2533 4.85998C11.5133 4.59998 11.9333 4.59998 12.1933 4.85998C12.4533 5.11998 12.4533 5.53998 12.1933 5.79998L7.13325 10.86C6.87992 11.12 6.45325 11.12 6.19325 10.86Z" />
		</g>
		<defs>
			<clipPath id="clip0_3454_6246">
				<rect width="16" height="16" />
			</clipPath>
		</defs>
	</svg>
);

export default CheckmarkIcon;
