import React from 'react';

const WhiteCheck = (props?) => {
	return (
<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Interface / Check">
    <path id="Vector" d="M6 12L10.2426 16.2426L18.727 7.75732" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </g>
</svg>
	)
}

export default WhiteCheck;