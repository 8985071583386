import React, { useState } from "react";

const UnlinkIcon = (props) => (
	<svg fill='white' viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg"
		style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: "2" }} {...props}>
		<rect id="Icons" x="0" y="0" />
		<path id="unlink" d="M25.756,24.135l-13.108,-13.108l3.096,-3.097l39.854,39.853l-3.097,3.097l-13.663,-13.663c-0.479,5.948 -6.655,10.643 -11.677,15.608c-2.086,2.008 -4.942,3.152 -7.842,3.134c-8.317,-0.154 -14.619,-11.624 -7.763,-18.739c3.923,-3.972 7.61,-8.964 11.931,-10.816c0.338,-0.145 0.681,-0.268 1.029,-0.371c0.015,1.283 0.123,2.918 0.495,4.281c-0.701,0.282 -1.357,0.69 -1.934,1.232c-4.472,4.311 -10.909,8.453 -10.504,13.891c0.257,3.45 3.395,6.412 6.969,6.389c1.757,-0.032 3.469,-0.744 4.733,-1.96c5.086,-5.028 12.486,-10.213 9.87,-16.114c-0.516,-1.163 -1.387,-2.1 -2.445,-2.767c-0.079,-0.341 -0.154,-0.718 -0.216,-1.122l-2.133,-2.134c0.011,0.79 0.181,1.593 0.543,2.409c0.515,1.162 1.386,2.1 2.445,2.767c0.279,1.209 0.513,2.876 0.268,4.562c-3.992,-1.537 -7.263,-5.189 -7.43,-9.714c-0.047,-1.259 0.166,-2.461 0.579,-3.618Zm4.438,-6.578c2.066,-2.197 4.485,-4.319 6.683,-6.492c2.086,-2.009 4.942,-3.153 7.842,-3.135c8.317,0.155 14.62,11.625 7.763,18.74c-2.155,2.182 -4.239,4.672 -6.396,6.78l-3.025,-3.026c4.138,-3.653 8.749,-7.343 8.405,-11.971c-0.257,-3.451 -3.396,-6.412 -6.97,-6.39c-1.757,0.033 -3.469,0.744 -4.732,1.96c-2.124,2.1 -4.651,4.226 -6.683,6.421l-2.887,-2.887Z" stroke='none' />
	</svg>
);

export default UnlinkIcon;
